<template>
  <b-card>
    <b-row
      align-v="start"
      class="mb-2"
    >
      <b-col
        cols="8"
      >
        <v-select
          v-model="topItems.selectedMode"
          :clearable="false"
          :options="topItems.modeSelection"
        />
      </b-col>
      <b-col
        cols="auto"
        class="ml-auto"
      >
        <feather-icon
          v-b-toggle.sidebar.sidebar-right
          icon="MoreVerticalIcon"
          size="16"
          class="text-body align-middle"
        />
      </b-col>
    </b-row>
    <b-overlay :show="topItems.loading">
      <vue-apex-charts
        v-if="chartData.series.length"
        width="100%"
        :options="chartData.options"
        :series="chartData.series"
        class="mt-2 mb-2"
      />
      <div
        v-else
        class="d-flex flex-column align-items-center"
      >
        <Lottie
          class="w-50 mb-1"
          :options="{
            animationData: noresult,
          }"
        />
        <h6 class="text-muted mb-1">
          Data Tidak Ditemukan 😞
        </h6>
        <h6
          v-b-toggle.sidebar.sidebar-right
          class="text-primary cursor-pointer"
        >
          Coba Filter Lain?
        </h6>
      </div>
    </b-overlay>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter Chart"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      @hidden="fetchDashboardPerformance()"
    >
      <div class="px-2 py-1">
        <label>Range Tanggal</label>
        <date-range-picker
          v-model="topItems.dateFilter"
          wrap
          :custom-range="range()"
        />
        <b-row class="mt-1">
          <b-col
            cols="6"
          >
            <label>Jumlah</label>
            <v-select
              v-model="topItems.topN"
              :clearable="false"
              :options="[5, 10]"
            />
          </b-col>
          <b-col
            cols="6"
          >
            <label>Tampilkan</label>
            <v-select
              v-model="topItems.owned"
              :clearable="false"
              :options="[
                {
                  value: 'all',
                  label: 'Semua',
                },
                {
                  value: 'mine',
                  label: 'Milik Saya',
                },
              ]"
            />
          </b-col>
        </b-row>
        <div class="mt-1">
          <label>DB Reference</label>
          <v-select
            v-model="topItems.ref_db"
            placeholder="Pilih DB"
            :options="referenceDBList"
            label="name"
            :clearable="false"
          />
        </div>
      </div>
    </b-sidebar>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, VBToggle, BSidebar, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import { createHelpers } from 'vuex-map-fields'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { formatCurrency } from '@core/utils/filter'
import Lottie from 'vue-lottie'
import noresult from '@/assets/images/lottie/noresult.json'

const { mapFields } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BSidebar,
    VueApexCharts,
    DateRangePicker,
    vSelect,
    BOverlay,
    Lottie,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      noresult,
      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            colors: $themeColors.secondary,
          },
          floating: true,
          formatter: name => (name.length > 24 ? `${name.substring(0, 24)}...` : name),
        },
        comparedResult: [2, -3, 8],
        labels: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'],
        stroke: { width: 0 },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.info, $themeColors.danger, $themeColors.secondary],
        grid: {
          padding: {
            right: -20,
            bottom: 80,
            left: -20,
          },
        },
      },
    }
  },
  computed: {
    ...mapFields([
      'topItems',
    ]),
    ...mapGetters({
      referenceDBList: 'getReferenceDBList',
    }),
    chartData() {
      const viewMode = this.topItems.selectedMode.value
      const dataResult = this.topItems.fetchResults[viewMode]
        ? this.topItems.fetchResults[viewMode].filter(el => el.total_rupiah !== null && el.total_rupiah !== 0).filter(el => {
          if (viewMode === 'items') {
            return el.item_name !== null
          }

          if (viewMode === 'customers') {
            return el.customer_name !== null
          }

          return el.user_name !== null
        })
        : []

      return {
        options: {
          ...this.chartOptions,
          tooltip: {
            y: {
              formatter(value, { seriesIndex }) {
                return `${formatCurrency(value)} - ${dataResult[seriesIndex].total_liter ? dataResult[seriesIndex].total_liter : 0} Liter`
              },
            },
          },
          labels: dataResult.length ? dataResult.map(el => {
            if (viewMode === 'items') {
              return el.item_name
            }

            if (viewMode === 'customers') {
              return el.customer_name
            }

            return el.user_name
          }) : [],
        },
        series: dataResult.length ? dataResult.map(el => el.total_rupiah) : [],
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.topItems.ref_db = this.$store.state.reference.selectedDB

      if (!this.chartData.series.length) {
        this.fetchDashboardPerformance()
      }
    }, 500)
  },
  methods: {
    formatCurrency,
    fetchDashboardPerformance() {
      this.topItems.loading = true
      /* eslint-disable camelcase */
      const ref_db = this.topItems.ref_db.id
      const date = this.topItems.dateFilter.startDate ? {
        date_min: new Date(Date.parse(this.topItems.dateFilter.startDate)).toISOString().slice(0, 10),
        date_max: new Date(Date.parse(this.topItems.dateFilter.endDate)).toISOString().slice(0, 10),
      } : null
      const top_n = this.topItems.topN
      const owned = this.topItems.owned.value !== 'all'

      this.$store.dispatch('getDashboardPerformance', {
        ref_db,
        date,
        top_n,
        owned,
      }).then(data => {
        this.topItems.fetchResults = data
        this.topItems.loading = false
      }).catch(() => {
        this.topItems.loading = false
      })
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const quarter = Math.floor((today.getMonth() + 3) / 3)
      const semester = Math.floor((today.getMonth() + 6) / 6)

      let prevQuarter
      if (quarter === 1) {
        prevQuarter = new Date(today.getFullYear() - 1, 9, 1)
      } else {
        prevQuarter = new Date(today.getFullYear(), (quarter - 2) * 3, 1)
      }
      const prevQuarterEnd = new Date(prevQuarter.getFullYear(), prevQuarter.getMonth() + 3, 0, 11, 59, 59, 999)
      const thisQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1)
      const thisQuarterEnd = new Date(today.getFullYear(), quarter * 3, 0)

      let prevSemester
      if (semester === 1) {
        prevSemester = new Date(today.getFullYear() - 1, 6, 1)
      } else {
        prevSemester = new Date(today.getFullYear(), semester - 2, 1)
      }
      const prevSemesterEnd = new Date(prevSemester.getFullYear(), prevSemester.getMonth() + 6, 0, 11, 59, 59)
      const thisSemester = new Date(today.getFullYear(), (semester - 1) * 6, 1)
      const thisSemesterEnd = new Date(today.getFullYear(), semester * 6, 0)

      /* eslint-disable quote-props */
      return {
        'Bulan lalu': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
        'Triwulan Lalu': [prevQuarter, prevQuarterEnd],
        'Semester Lalu': [prevSemester, prevSemesterEnd],
        'Triwulan Ini': [thisQuarter, thisQuarterEnd],
        'Semester Ini': [thisSemester, thisSemesterEnd],
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}
</style>
