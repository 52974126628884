var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex align-items-center pl-2 pr-1 pt-2 pb-1 mb-1"},[_c('h4',{staticClass:"m-0"},[_vm._v(" Quote Pending ")]),_c('router-link',{staticClass:"ml-auto mr-50",attrs:{"to":{ name: 'quotations', params: { filter: {
        status: _vm.pendingQuotes.mode === 'needapproval'? [
          { id: 2, label: 'Submitted' } ] : [
          { id: 1, label: 'Draft' },
          { id: 2, label: 'Submitted' },
          { id: 3, label: 'Approved' },
          { id: 4, label: 'Rejected By Manager' },
          { id: 5, label: 'Rejected By Customer' } ],
        userCreate: _vm.$root.role === 2 && _vm.pendingQuotes.mode !== 'mine' ? [] : [
          _vm.$store.state.user.currentUser.user
        ],
      }}}}},[_c('small',{staticClass:"text-primary cursor-pointer font-weight-bold"},[_vm._v(" Lihat Semua "+_vm._s(_vm.getQuoteCount())+" ")])]),_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none p-0","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.fetchPendingQuotes()}}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"RefreshCcwIcon"}}),_c('span',{staticClass:"text-primary"},[_vm._v("Refresh")])],1),(_vm.$root.role === 2)?_c('b-dropdown-divider'):_vm._e(),(_vm.$root.role === 2)?_c('b-dropdown-item',{attrs:{"active":_vm.pendingQuotes.mode === 'needapproval'},on:{"click":function($event){_vm.pendingQuotes.mode = 'needapproval'}}},[_vm._v(" Need Approval ")]):_vm._e(),(_vm.$root.role === 2)?_c('b-dropdown-item',{attrs:{"active":_vm.pendingQuotes.mode === 'mine'},on:{"click":function($event){_vm.pendingQuotes.mode = 'mine'}}},[_vm._v(" Milik Saya ")]):_vm._e(),(_vm.$root.role === 2)?_c('b-dropdown-item',{attrs:{"active":_vm.pendingQuotes.mode === 'all'},on:{"click":function($event){_vm.pendingQuotes.mode = 'all'}}},[_vm._v(" Lainnya ")]):_vm._e()],1)],1),_c('b-overlay',{attrs:{"show":_vm.pendingQuotes.loading}},[(_vm.quoteList && !_vm.quoteList.length)?_c('div',{staticClass:"px-2 d-flex flex-column justify-content-center align-items-center"},[_c('Lottie',{staticClass:"w-50",attrs:{"options":{
          animationData: _vm.noresult,
        }}}),_c('h6',{staticClass:"text-muted font-weight-normal mb-3"},[_vm._v(" Belum ada data ")])],1):_c('div',{staticClass:"horizontal-scroll px-2"},[_c('table',_vm._l((_vm.quoteList),function(quote){return _c('tr',{key:quote.id},[_c('td',{staticClass:"pr-2 pb-1"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{ name: 'quotation-detail', params: {id: quote.id.toString(), version: quote.last_version.toString()} }}},[_c('small',{staticClass:"text-primary mr-25 font-weight-bolder"},[_vm._v(" "+_vm._s(quote.name)+" ")])]),_c('small',[_c('b-badge',{attrs:{"variant":_vm.statusOptions[quote.status.id].badge}},[_vm._v(" "+_vm._s(_vm.statusOptions[quote.status.id].label)+" ")])],1)],1),_c('small',{staticClass:"text-muted d-block"},[_vm._v(" Rp. "+_vm._s(_vm.shortNumber(quote.total))+" "),(_vm.$root.role === 2)?_c('span',[_vm._v(" (HPP: Rp. "+_vm._s(_vm.shortNumber(quote.total_hpp))+") ")]):_vm._e()])]),_c('td',{staticClass:"pr-2 pb-1"},[_c('router-link',{attrs:{"to":{ name: 'project-detail', params: {id: quote.project.id.toString()} }}},[_c('small',{staticClass:"d-block text-primary font-weight-bold"},[_vm._v(" "+_vm._s(quote.project.name)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"text-secondary mr-25"},[_vm._v(" "+_vm._s(_vm.formatDate(quote.project.tgl_reminder))+" ")])])],1),_c('td',{staticClass:"pr-2 pb-1"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"text":_vm.avatarText(quote.userCreate.name),"variant":"light-primary","src":quote.userCreate.photo && quote.userCreate.photo !== 'default.png' ?
                  (_vm.$restHTTP + "/assets/img/profile-picture/" + (quote.userCreate.id) + "/" + (quote.userCreate.photo)) :
                  (_vm.$restHTTP + "/assets/img/profile-picture/default.png")}}),_c('div',[_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: quote.userCreate.id.toString() } }}},[_c('small',{staticClass:"d-block text-primary font-weight-bold"},[_vm._v(" "+_vm._s(quote.userCreate.name)+" ")])]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(quote.userCreate.role.name)+" ")])],1)],1)])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }