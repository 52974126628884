<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade stagger-1 left-3 delay-3"
    >
      <b-row
        :key="1"
        class="match-height"
      >
        <b-col
          cols="12"
          xl="8"
        >
          <welcome-card />
        </b-col>
        <b-col
          cols="12"
          xl="4"
        >
          <omzet-card />
        </b-col>
      </b-row>

      <b-row
        :key="3"
        class="match-height"
      >
        <b-col
          cols="12"
          xl="4"
        >
          <b-row class="match-height h-100">
            <b-col
              cols="12"
              xl="12"
              md="6"
            >
              <b-card
                no-body
                style="height: 100px; max-height: 100px;"
              >
                <water-fall-slider
                  :height="100"
                >
                  <swiper-slide v-if="$root.role === 2">
                    <div class="h-100 p-1 d-flex flex-column justify-content-between">
                      <small class="text-muted">Status Quote</small>
                      <div class="d-flex justify-content-between align-items-end">
                        <h1 class="text-primary m-0 font-weight-bolder">
                          {{ quoteBagde.quote_submitted ? quoteBagde.quote_submitted : 0 }}
                        </h1>
                        <h1 class="m-0">
                          ✅
                        </h1>
                      </div>
                      <small class="text-muted">Jumlah Quote Disubmit</small>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="h-100 p-1 d-flex flex-column justify-content-between">
                      <small class="text-muted">Status Quote</small>
                      <div class="d-flex justify-content-between align-items-end">
                        <h1 class="text-danger m-0 font-weight-bolder">
                          {{ quoteBagde.quote_action ? quoteBagde.quote_action : 0 }}
                        </h1>
                        <h1 class="m-0">
                          📢
                        </h1>
                      </div>
                      <small class="text-muted">Quote Saya Yang Membutuhkan Aksi</small>
                    </div>
                  </swiper-slide>
                  <swiper-slide v-if="$root.role === 2">
                    <div class="h-100 p-1 d-flex flex-column justify-content-between">
                      <small class="text-muted">Status Quote</small>
                      <div class="d-flex justify-content-between align-items-end">
                        <h1 class="text-warning m-0 font-weight-bolder">
                          {{ quoteBagde.all_quote_action ? quoteBagde.all_quote_action : 0 }}
                        </h1>
                        <h1 class="m-0">
                          🚨
                        </h1>
                      </div>
                      <small class="text-muted">Semua Quote Yang Membutuhkan Aksi</small>
                    </div>
                  </swiper-slide>
                </water-fall-slider>
              </b-card>
              <quote-card />
            </b-col>
            <b-col
              cols="12"
              xl="12"
              md="6"
            >
              <top-items />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          xl="8"
        >
          <project-calendar />
        </b-col>
      </b-row>
      <chart-penjualan :key="4" />
      <performance-task
        :id="$store.getters.getCurrentUser.user.id"
        :key="5"
      />
    </XyzTransitionGroup>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import WaterFallSlider from '@/components/misc/WaterfallSlider.vue'
import { SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import WelcomeCard from './home/WelcomeCard.vue'
import ProjectCalendar from './home/ProjectCalendar.vue'
import QuoteCard from './home/QuoteCard.vue'
import OmzetCard from './home/OmzetCard.vue'
import PerformanceTask from './home/PerformanceTask.vue'
import TopItems from './home/TopItems.vue'
import ChartPenjualan from './home/ChartPenjualan.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    WelcomeCard,
    WaterFallSlider,
    SwiperSlide,
    ProjectCalendar,
    QuoteCard,
    OmzetCard,
    PerformanceTask,
    TopItems,
    ChartPenjualan,
  },
  computed: {
    ...mapGetters({
      quoteBagde: 'getQuotesBadge',
    }),
  },
}
</script>
