<template>
  <b-card>
    <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-1">
      <h6 class="m-0">
        Performance Penjualan
      </h6>
    </div>
    <b-row class="mb-1">
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="viewMode"
          placeholder="Pilih satuan"
          :options="typeMode.value === 'Q' ? [{ label: 'Rupiah', value: 'rp'}, { label: 'Liter', value: 'lt'},] : [{ label: 'Rupiah', value: 'rp'}]"
          :clearable="false"
          @option:selected="renderChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="refDb"
          placeholder="Ref DB"
          :options="[{ label: 'TSD', value: 0 }, { label: 'PD', value: 1 }]"
          :clearable="false"
          @option:selected="getChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
        class="mb-1"
      >
        <v-select
          v-model="typeMode"
          placeholder="Pilih tipe"
          :options="[{ label: 'Quote', value: 'Q'}, { label: 'Sales Order', value: 'SO'}, { label: 'Sales Invoice', value: 'SI'},]"
          :clearable="false"
          @option:selected="getChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="6"
      >
        <date-range-picker
          v-model="date"
          :max-date="today"
          :min-date="new Date(today.getFullYear(), today.getMonth() - 12, 1)"
          :custom-range="range()"
          opens="left"
        />
      </b-col>
    </b-row>
    <b-row v-if="!notfound">
      <b-col
        cols="12"
        xl="9"
      >
        <div
          v-if="loadingChart"
          class="d-flex justify-content-center align-items-center"
          style="height: 20vh"
        >
          <b-spinner variant="primary" />
        </div>
        <chartjs-component-bar-chart
          v-else
          :key="chartData.toString()"
          :height="320"
          :data="chartData"
          :options="options"
        />
      </b-col>
      <b-col
        v-if="!loadingChart"
        cols="12"
        xl="3"
        class="mt-1 mt-xl-0"
      >
        <small class="text-primary">Akumulasi Penjualan</small>
        <div class="d-flex flex-row flex-xl-column flex-wrap mb-1 mt-50">
          <div class="mr-1">
            <small class="text-muted">Total</small>
            <h4>{{ formatCurrency(performanceData.accumulation_total) }}</h4>
          </div>
          <div class="mr-1">
            <small class="text-muted">Total Liter</small>
            <h4>{{ formatNumber(performanceData.accumulation_total_liter) }}</h4>
          </div>
          <div v-if="permission.viewHpp">
            <small class="text-muted">Total HPP</small>
            <h4>{{ formatCurrency(performanceData.accumulation_total_hpp) }}</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <div
      v-else
      style="height: 400px"
    >
      <h6 class="text-muted">
        Tidak ada data
      </h6>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, VBToggle, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import { formatCurrency, avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import ChartjsComponentBarChart from '../../components/chart/ChartjsComponentBarChart.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    ChartjsComponentBarChart,
    vSelect,
    DateRangePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      formatCurrency,
      avatarText,
      loadingChart: true,
      notfound: false,
      viewMode: { label: 'Rupiah', value: 'rp' },
      typeMode: { label: 'Quote', value: 'Q' },
      performanceData: '',
      refDb: { label: 'TSD', value: 0 },
      swiperOption: {
        speed: 800,
        slidesPerView: 1,
        mousewheel: true,
        spaceBetween: 30,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        loop: false,
        centeredSlides: true,
      },
      chartData: {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [
          {
            label: 'Total HPP',
            data: [],
            borderColor: '#17a2b8',
            type: 'line',
            fill: true,
            spanGaps: true,
          },
          {
            label: 'Target',
            data: [],
            borderColor: $themeColors.warning,
            type: 'line',
            spanGaps: true,
          },
          {
            label: 'Penjualan User',
            data: [],
            backgroundColor: '#6DAB3C',
            borderColor: 'transparent',
            stack: 'Stack 1',
            barBottom: true,
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                min: 0,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      },
      date: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1).setHours(0, 0, 0, 0),
        endDate: new Date().setHours(0, 0, 0, 0),
      },
      filterMode: false,
      today: new Date(),
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
  },
  watch: {
    date: {
      handler() {
        this.getChart()
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.refDb = {
        label: this.$store.state.reference.selectedDB.name,
        value: this.$store.state.reference.selectedDB.id,
      }
      this.getChart()
    }, 1000)
  },
  methods: {
    async getChart() {
      this.loadingChart = true

      if (this.typeMode.value !== 'Q') {
        this.viewMode = { label: 'Rupiah', value: 'rp' }
      }

      this.$store.dispatch('getPerformancePenjualan', {
        date: {
          date_min: new Date(this.date.startDate).toISOString().slice(0, 10),
          date_max: new Date(this.date.endDate).toISOString().slice(0, 10),
        },
        data_source: this.typeMode.value,
        ref_db: this.refDb.value,
      }).then(async mySale => {
        this.performanceData = {
          ...mySale,
          detail_performance: mySale.detail_performance,
        }

        this.renderChart()
      })
    },
    renderChart() {
      this.resetChart()
      this.loadingChart = true

      const labels = []

      if (this.performanceData.detail_performance && this.performanceData.detail_performance.length) {
        this.performanceData.detail_performance.forEach(data => {
          if (!labels.some(el => el === this.formatMonth(data.date))) {
            labels.push(this.formatMonth(data.date))
          }
        })
      }

      labels.forEach((el, index) => {
        this.chartData.labels[index] = el
      })

      if (this.performanceData.detail_performance && this.performanceData.detail_performance.length) {
        this.performanceData.detail_performance.forEach(data => {
          const index = this.chartData.labels.findIndex(el => el === this.formatMonth(data.date))
          // eslint-disable-next-line no-nested-ternary
          this.chartData.datasets[0].data[index] = this.permission.viewHpp ? this.viewMode.value === 'rp' ? data.total_hpp : 0 : null
          this.chartData.datasets[2].data[index] = this.viewMode.value === 'rp' ? data.total : this.formatNumber(data.total_liter)
        })
      } else {
        this.chartData.datasets[2].data = []
        this.chartData.datasets[0].data = []
      }

      if (!this.performanceData.detail_performance) {
        this.notfound = true
      }

      setTimeout(() => {
        this.loadingChart = false
      }, 500)
    },
    resetChart() {
      this.notfound = false
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[2].data = []
      this.chartData.labels = ['', '', '', '', '', '', '', '', '', '', '', '', '']
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayEnd = new Date()
      todayEnd.setHours(11, 59, 59, 999)
      const yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      const yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)
      /* eslint-disable quote-props */
      return {
        '3 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 3, 1), today],
        '6 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 6, 1), today],
        '1 Tahun Terakhir': [new Date(today.getFullYear(), today.getMonth() - 12, 1), today],
        'Tahun ini': [new Date(today.getFullYear(), 0, 1), today],
      }
    },
    chartColors() {
      return {
        primaryColorShade: '#6DAB3C',
        primaryLighteColorShade: 'rgba(109, 171, 60, 0.4)',
        secondaryColorShade: '#ff9f43',
        secondaryLightColorShade: 'rgba(255,159,67, 0.4)',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      }
    },
    applyFilter() {
      this.getChart()
      this.filterMode = false
    },
    formatNumber(data) {
      return Math.ceil(data * 100) / 100
    },
    formatMonth(date) {
      const parsedDate = new Date(Date.parse(date))

      return moment(parsedDate).locale('id').format('MM/YY')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
