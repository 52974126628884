<template>
  <b-card
    text-variant="left"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <Lottie
      class="hello-lottie"
      :options="{
        animationData: shipwoofwoof,
      }"
      :width="300"
    />
    <!--/ images -->
    <div class="d-flex flex-column align-items-start h-100">
      <h1 class="mb-1 mt-auto text-white">
        Halo, {{ $store.getters.getCurrentUser.user.name }}!
      </h1>
      <b-card-text class="mb-1">
        Selamat Datang di Quotation System!
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText,
} from 'bootstrap-vue'
import Lottie from 'vue-lottie'
import shipwoofwoof from '@/assets/images/lottie/shipwoofwoof.json'

export default {
  components: {
    BCard,
    BImg,
    BCardText,
    Lottie,
  },
  data() {
    return {
      shipwoofwoof,
    }
  },
}
</script>

<style>
.hello-lottie {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: scale(1.2);
  overflow: hidden;
}

@media(max-width: 1300px) {
  .hello-lottie {
    transform: scale(1);
    bottom: 20px;
    right: -40px;
  }
}

@media(max-width: 1199px) {
  .hello-lottie {
    bottom: 0px;
    right: 0px;
    transform: scale(1.2)
  }
}

@media(max-width: 500px) {
  .hello-lottie {
    display: none;
  }
}

.card-congratulations {
  height: 240px;
}
</style>
