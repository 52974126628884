var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"start"}},[_c('b-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.topItems.modeSelection},model:{value:(_vm.topItems.selectedMode),callback:function ($$v) {_vm.$set(_vm.topItems, "selectedMode", $$v)},expression:"topItems.selectedMode"}})],1),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('feather-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar.sidebar-right",modifiers:{"sidebar":true,"sidebar-right":true}}],staticClass:"text-body align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})],1)],1),_c('b-overlay',{attrs:{"show":_vm.topItems.loading}},[(_vm.chartData.series.length)?_c('vue-apex-charts',{staticClass:"mt-2 mb-2",attrs:{"width":"100%","options":_vm.chartData.options,"series":_vm.chartData.series}}):_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('Lottie',{staticClass:"w-50 mb-1",attrs:{"options":{
          animationData: _vm.noresult,
        }}}),_c('h6',{staticClass:"text-muted mb-1"},[_vm._v(" Data Tidak Ditemukan 😞 ")]),_c('h6',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar.sidebar-right",modifiers:{"sidebar":true,"sidebar-right":true}}],staticClass:"text-primary cursor-pointer"},[_vm._v(" Coba Filter Lain? ")])],1)],1),_c('b-sidebar',{ref:"sidebarRight",staticClass:"sidebar",attrs:{"id":"sidebar-right","title":"Filter Chart","right":"","shadow":"","backdrop":"","backdrop-variant":"transparent"},on:{"hidden":function($event){return _vm.fetchDashboardPerformance()}}},[_c('div',{staticClass:"px-2 py-1"},[_c('label',[_vm._v("Range Tanggal")]),_c('date-range-picker',{attrs:{"wrap":"","custom-range":_vm.range()},model:{value:(_vm.topItems.dateFilter),callback:function ($$v) {_vm.$set(_vm.topItems, "dateFilter", $$v)},expression:"topItems.dateFilter"}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Jumlah")]),_c('v-select',{attrs:{"clearable":false,"options":[5, 10]},model:{value:(_vm.topItems.topN),callback:function ($$v) {_vm.$set(_vm.topItems, "topN", $$v)},expression:"topItems.topN"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Tampilkan")]),_c('v-select',{attrs:{"clearable":false,"options":[
              {
                value: 'all',
                label: 'Semua',
              },
              {
                value: 'mine',
                label: 'Milik Saya',
              } ]},model:{value:(_vm.topItems.owned),callback:function ($$v) {_vm.$set(_vm.topItems, "owned", $$v)},expression:"topItems.owned"}})],1)],1),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v("DB Reference")]),_c('v-select',{attrs:{"placeholder":"Pilih DB","options":_vm.referenceDBList,"label":"name","clearable":false},model:{value:(_vm.topItems.ref_db),callback:function ($$v) {_vm.$set(_vm.topItems, "ref_db", $$v)},expression:"topItems.ref_db"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }