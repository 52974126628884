<template>
  <b-card no-body>
    <div class="d-flex align-items-center pl-2 pr-1 pt-2 pb-1 mb-1">
      <h4 class="m-0">
        Quote Pending
      </h4>
      <router-link
        class="ml-auto mr-50"
        :to="{ name: 'quotations', params: { filter: {
          status: pendingQuotes.mode === 'needapproval'? [
            { id: 2, label: 'Submitted' },
          ] : [
            { id: 1, label: 'Draft' },
            { id: 2, label: 'Submitted' },
            { id: 3, label: 'Approved' },
            { id: 4, label: 'Rejected By Manager' },
            { id: 5, label: 'Rejected By Customer' },
          ],
          userCreate: $root.role === 2 && pendingQuotes.mode !== 'mine' ? [] : [
            $store.state.user.currentUser.user
          ],
        }}}"
      >
        <small
          class="text-primary cursor-pointer font-weight-bold"
        >
          Lihat Semua {{ getQuoteCount() }}
        </small>
      </router-link>
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        right
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle"
          />
        </template>
        <b-dropdown-item @click="fetchPendingQuotes()">
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50 text-primary"
          />
          <span class="text-primary">Refresh</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="$root.role === 2" />
        <b-dropdown-item
          v-if="$root.role === 2"
          :active="pendingQuotes.mode === 'needapproval'"
          @click="pendingQuotes.mode = 'needapproval'"
        >
          Need Approval
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$root.role === 2"
          :active="pendingQuotes.mode === 'mine'"
          @click="pendingQuotes.mode = 'mine'"
        >
          Milik Saya
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$root.role === 2"
          :active="pendingQuotes.mode === 'all'"
          @click="pendingQuotes.mode = 'all'"
        >
          Lainnya
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-overlay :show="pendingQuotes.loading">
      <div
        v-if="quoteList && !quoteList.length"
        class="px-2 d-flex flex-column justify-content-center align-items-center"
      >
        <Lottie
          class="w-50"
          :options="{
            animationData: noresult,
          }"
        />
        <h6 class="text-muted font-weight-normal mb-3">
          Belum ada data
        </h6>
      </div>
      <div
        v-else
        class="horizontal-scroll px-2"
      >
        <table>
          <tr
            v-for="quote in quoteList"
            :key="quote.id"
          >
            <td class="pr-2 pb-1">
              <div class="d-flex">
                <router-link :to="{ name: 'quotation-detail', params: {id: quote.id.toString(), version: quote.last_version.toString()} }">
                  <small class="text-primary mr-25 font-weight-bolder">
                    {{ quote.name }}
                  </small>
                </router-link>
                <small>
                  <b-badge :variant="statusOptions[quote.status.id].badge">
                    {{ statusOptions[quote.status.id].label }}
                  </b-badge>
                </small>
              </div>
              <small class="text-muted d-block">
                Rp. {{ shortNumber(quote.total) }}
                <span v-if="$root.role === 2">
                  (HPP: Rp. {{ shortNumber(quote.total_hpp) }})
                </span>
              </small>
            </td>
            <td class="pr-2 pb-1">
              <router-link :to="{ name: 'project-detail', params: {id: quote.project.id.toString()} }">
                <small class="d-block text-primary font-weight-bold">
                  {{ quote.project.name }}
                </small>
              </router-link>
              <div class="d-flex">
                <small class="text-secondary mr-25">
                  {{ formatDate(quote.project.tgl_reminder) }}
                </small>
              </div>
            </td>
            <td
              class="pr-2 pb-1"
            >
              <div class="d-flex">
                <b-avatar
                  :text="avatarText(quote.userCreate.name)"
                  variant="light-primary"
                  class="mr-50"
                  :src="quote.userCreate.photo && quote.userCreate.photo !== 'default.png' ?
                    `${$restHTTP}/assets/img/profile-picture/${quote.userCreate.id}/${quote.userCreate.photo}` :
                    `${$restHTTP}/assets/img/profile-picture/default.png`"
                />
                <div>
                  <router-link :to="{ name: 'user-detail', params: { id: quote.userCreate.id.toString() } }">
                    <small class="d-block text-primary font-weight-bold">
                      {{ quote.userCreate.name }}
                    </small>
                  </router-link>
                  <small class="text-muted">
                    {{ quote.userCreate.role.name }}
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BDropdown, BDropdownItem, BDropdownDivider, BBadge, BAvatar, BOverlay,
} from 'bootstrap-vue'
import { createHelpers } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import { shortNumber, formatDate, avatarText } from '@core/utils/filter'
import Lottie from 'vue-lottie'
import noresult from '@/assets/images/lottie/noresult.json'

const { mapFields } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BAvatar,
    BOverlay,
    Lottie,
  },
  data() {
    return {
      noresult,
    }
  },
  computed: {
    ...mapFields([
      'pendingQuotes',
    ]),
    ...mapGetters({
      statusOptions: 'getStatusOptions',
      quoteBadge: 'getQuotesBadge',
    }),
    quoteList() {
      if (this.$root.role === 2) {
        if (this.pendingQuotes.mode === 'all') {
          return this.pendingQuotes.quotePendingManager.all_dashboard_quote
        }

        if (this.pendingQuotes.mode === 'mine') {
          return this.pendingQuotes.quotePendingManager.my_dashboard_quote
        }

        return this.pendingQuotes.quotePendingManager.all_submitted
      }

      return this.pendingQuotes.quoteList
    },
  },
  mounted() {
    if (!this.pendingQuotes.quoteList.length) {
      this.fetchPendingQuotes()
    }
  },
  methods: {
    shortNumber,
    formatDate,
    avatarText,
    fetchPendingQuotes() {
      if (this.$root.role === 2) {
        this.$store.dispatch('getPendingQuotesManager')
      } else {
        this.$store.dispatch('getPendingQuotes')
      }
    },
    getQuoteCount() {
      if (this.$root.role === 2) {
        let count = this.quoteBadge.quote_submitted ? this.quoteBadge.quote_submitted : 0

        if (this.pendingQuotes.mode === 'all') count = this.quoteBadge.all_quote_action ? this.quoteBadge.all_quote_action : 0
        if (this.pendingQuotes.mode === 'mine') count = this.quoteBadge.quote_action ? this.quoteBadge.quote_action : 0

        return count ? `(${count > 99 ? '99+' : count})` : null
      }

      return this.quoteBadge.quote_action > 0 ? `(${this.quoteBadge.quote_action})` : null
    },
  },
}
</script>

<style>
.horizontal-scroll {
  white-space: pre;
  overflow-x: auto;
}
</style>
