<template>
  <b-card>
    <h4 class="m-0 mb-50">
      Performance Task
    </h4>
    <b-row>
      <b-col
        cols="12"
        xl="8"
        class="mb-2 mb-xl-0"
      >
        <div
          v-if="performanceTasks.loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 40vh"
        >
          <b-spinner variant="primary" />
        </div>
        <chartjs-component-line-chart
          v-else
          :height="400"
          :data="lineChart.data"
          :options="lineChart.options"
          :plugins="plugins"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
      >
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex align-items-center justify-content-center">
            <b-dropdown
              :text="summaryLabel"
              size="sm"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="period in periods"
                :key="period.value"
                :active="performanceTasks.summaryPeriod === period.value"
                @click="performanceTasks.summaryPeriod === period.value ? null : changePeriod(period.value)"
              >
                {{ period.label }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <h6 class="mt-1">
            {{ periodLabel }}
          </h6>
          <vue-apex-charts
            v-if="performanceTasks.summaryData.length"
            :key="donutData.toString()"
            height="200"
            :options="donutChartOptions"
            :series="donutData"
            class="mt-2 mb-3"
          />
          <div class="w-75">
            <div class="d-flex mb-50 align-items-center">
              <div
                class="bullet bg-primary mr-50"
              />
              <h6 class="m-0">
                On Time
              </h6>
              <h6 class="m-0 ml-auto">
                {{ roundDecimal(donutData[0], 2) }}%
              </h6>
              <Lottie
                v-if="previousSummaryData && previousSummaryData.percentage_on_time !== donutData[0]"
                v-b-popover.hover.top="`Periode sebelumnya: ${roundDecimal(previousSummaryData.percentage_on_time, 2)}`"
                class="m-0 ml-25"
                :options="{
                  animationData: donutData[0] > previousSummaryData.percentage_on_time ? arrowUp : arrowDown
                }"
                :height="18"
                :width="18"
              />
            </div>
            <div class="d-flex mb-50 align-items-center">
              <div
                class="bullet bg-warning mr-50"
              />
              <h6 class="m-0">
                Terlambat
              </h6>
              <h6 class="m-0 ml-auto">
                {{ roundDecimal(donutData[1], 2) }}%
              </h6>
              <Lottie
                v-if="previousSummaryData && previousSummaryData.percentage_late !== donutData[1]"
                v-b-popover.hover.top="`Periode sebelumnya: ${roundDecimal(previousSummaryData.percentage_late, 2)}`"
                class="m-0 ml-25"
                :options="{
                  animationData: donutData[1] > previousSummaryData.percentage_late ? arrowUp : arrowDown
                }"
                :height="18"
                :width="18"
              />
            </div>
            <div class="d-flex mb-50 align-items-center">
              <div
                class="bullet bg-danger mr-50"
              />
              <h6 class="m-0">
                Belum Selesai
              </h6>
              <h6 class="m-0 ml-auto">
                {{ roundDecimal(donutData[2], 2) }}%
              </h6>
              <Lottie
                v-if="previousSummaryData && previousSummaryData.percentage_not_done !== donutData[2]"
                v-b-popover.hover.top="`Periode sebelumnya: ${roundDecimal(previousSummaryData.percentage_not_done, 2)}`"
                class="m-0 ml-25"
                :options="{
                  animationData: donutData[2] > previousSummaryData.percentage_not_done ? arrowUp : arrowDown
                }"
                :height="18"
                :width="18"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from '@/components/chart/ChartjsComponentLineChart.vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import { createHelpers } from 'vuex-map-fields'
import { roundDecimal } from '@core/utils/filter'
import Lottie from 'vue-lottie'
import arrowUp from '@/assets/images/lottie/arrow-up.json'
import arrowDown from '@/assets/images/lottie/arrow-down.json'
import moment from 'moment'

const { mapFields } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    ChartjsComponentLineChart,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
    Lottie,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      arrowUp,
      arrowDown,
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sept', 'Okt', 'Nov', 'Des'],
      periods: [
        {
          label: 'Bulanan',
          value: 'M',
        },
        {
          label: 'Triwulan',
          value: 'T',
        },
        {
          label: 'Semester',
          value: 'S',
        },
        {
          label: 'Tahunan',
          value: 'Y',
        },
      ],
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                stacked: true,
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 20,
              boxWidth: 9,
            },
          },
          tooltips: {
            mode: 'label',
            intersect: false,
          },
        },
        data: {
          labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
          datasets: [
            {
              data: [],
              label: 'On Time',
              borderColor: $themeColors.primary,
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: $themeColors.primary,
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: $themeColors.primary,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
              stack: '1',
            },
            {
              data: [],
              label: 'Terlambat',
              borderColor: $themeColors.warning,
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: $themeColors.warning,
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: $themeColors.warning,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
              stack: '1',
            },
            {
              data: [],
              label: 'Belum Selesai',
              borderColor: $themeColors.danger,
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: $themeColors.danger,
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: $themeColors.danger,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
              stack: '1',
            },
          ],
        },
      },
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 12
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'performanceTasks',
    ]),
    summaryLabel() {
      return this.periods.filter(el => el.value === this.performanceTasks.summaryPeriod)[0].label
    },
    donutChartOptions() {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        labels: ['On Time', 'Terlambat', 'Belum Selesai'],
        stroke: { width: 0 },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 20,
                },
                value: {
                  color: this.$store.state.appConfig.layout.skin === 'dark' ? $themeColors.light : $themeColors.dark,
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  color: '#e5e5e5',
                  label: 'Total Tasks',
                  fontSize: '1rem',
                  formatter: () => (this.performanceTasks.summaryData.length ? this.performanceTasks.summaryData[0].count : 0),
                },
              },
            },
          },
        },
      }
    },
    donutData() {
      if (this.performanceTasks.summaryData.length) {
        const data = this.performanceTasks.summaryData.filter(el => el.period === 'Recent')
        if (!data.length) return [0, 0, 0]
        return [+data[0].percentage_on_time, +data[0].percentage_late, +data[0].percentage_not_done]
      }
      return [0, 0, 0]
    },
    previousSummaryData() {
      if (this.performanceTasks.summaryData.length) {
        const data = this.performanceTasks.summaryData.filter(el => el.period === 'Recent')
        if (!data.length) return null
        return data[0]
      }
      return null
    },
    periodLabel() {
      const today = new Date()
      if (this.performanceTasks.summaryPeriod === 'M') {
        return moment().locale('id').format('MMM YYYY')
      }

      if (this.performanceTasks.summaryPeriod === 'T') {
        const quarter = Math.floor((today.getMonth() + 3) / 3)
        return `${moment(new Date(today.getFullYear(), (quarter - 1) * 3, 1)).locale('id').format('MMM YYYY')} - ${moment(new Date(today.getFullYear(), quarter * 3, 0)).locale('id').format('MMM YYYY')}`
      }

      if (this.performanceTasks.summaryPeriod === 'S') {
        const semester = Math.floor((today.getMonth() + 6) / 6)
        return `${moment(new Date(today.getFullYear(), (semester - 1) * 6, 1)).locale('id').format('MMM YYYY')} - ${moment(new Date(today.getFullYear(), semester * 6, 0)).locale('id').format('MMM YYYY')}`
      }

      if (this.performanceTasks.summaryPeriod === 'Y') {
        return `${moment(new Date(today.getFullYear(), 0, 1)).locale('id').format('MMM YYYY')} - ${moment(new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)).locale('id').format('MMM YYYY')}`
      }

      return moment().locale('id').format('MMM YYYY')
    },
  },
  mounted() {
    if (!this.performanceTasks.chartData.length) {
      this.fetchChart()
    } else {
      this.renderChart()
    }
  },
  methods: {
    roundDecimal,
    async fetchChart() {
      this.$store.commit('updateDashboardData', { path: 'performanceTasks.loading', value: true })
      await this.$store.dispatch(
        'getTasksPerformanceChart',
        +this.id,
      ).then(res => {
        this.$store.commit('updateDashboardData', { path: 'performanceTasks.chartData', value: res })
        this.renderChart()
        this.$store.commit('updateDashboardData', { path: 'performanceTasks.loading', value: false })
      })

      this.fetchSummary()
    },
    async fetchSummary() {
      await this.$store.dispatch('getTasksPerformanceStats', {
        id: this.id,
        period: this.performanceTasks.summaryPeriod,
      }).then(result => {
        this.$store.commit('updateDashboardData', { path: 'performanceTasks.summaryData', value: result })
      })
    },
    renderChart() {
      this.$store.commit('updateDashboardData', { path: 'performanceTasks.loading', value: true })
      this.lineChart.data.labels = this.performanceTasks.chartData.map(el => this.monthLabels[+el.month.substring(5, 7) - 1])
      this.lineChart.data.datasets[0].data = this.performanceTasks.chartData.map(el => +el.task_done)
      this.lineChart.data.datasets[1].data = this.performanceTasks.chartData.map(el => +el.task_late)
      this.lineChart.data.datasets[2].data = this.performanceTasks.chartData.map(el => +el.task_not_done)

      setTimeout(() => {
        this.$store.commit('updateDashboardData', { path: 'performanceTasks.loading', value: false })
      }, 100)
    },
    changePeriod(period) {
      this.performanceTasks.summaryPeriod = period
      this.fetchSummary()
    },
  },
}
</script>
