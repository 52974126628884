<template>
  <b-card>
    <div class="d-flex justify-content-between mb-1">
      <h4 class="m-0">
        Kalender Project
      </h4>
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        right
      >
        <template v-slot:button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle"
          />
        </template>
        <b-dropdown-item
          @click="getProject()"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50 text-primary"
          />
          <span class="text-primary">Refresh</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$root.role === 2"
          :active="selectedPerson === 'all'"
          @click="selectedPerson = 'all'; getProject()"
        >
          Semua
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$root.role === 2"
          :active="selectedPerson === 'mine'"
          @click="selectedPerson = 'mine'; getProject()"
        >
          Milik Saya
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-overlay :show="loadingProject">
      <full-calendar :options="calendarOptions" />
    </b-overlay>
    <div class="d-flex flex-wrap justify-content-center mt-1">
      <div class="d-flex align-items-center mr-2">
        <div class="p-75 bg-danger rounded mr-50" />
        <small class="text-secondary">Tidak Aktif</small>
      </div>
      <div class="d-flex align-items-center mr-2">
        <div class="p-75 bg-warning rounded mr-50" />
        <small class="text-secondary">Jatuh Tempo</small>
      </div>
      <div class="d-flex align-items-center">
        <div class="p-75 bg-success rounded mr-50" />
        <small class="text-secondary">Aktif</small>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BOverlay, BDropdown, BDropdownItem, BPopover,
} from 'bootstrap-vue'
import { createHelpers } from 'vuex-map-fields'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import idLocale from '@fullcalendar/core/locales/id'
import moment from 'moment'

const { mapFields } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

export default {
  components: {
    FullCalendar,
    BOverlay,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapFields([
      'loadingProject',
      'projectList',
      'selectedPerson',
    ]),
    calendarEvents() {
      return this.projectList.map(el => ({
        id: el.id,
        title: el.name,
        date: new Date(el.tgl_reminder),
        allDay: true,
        backgroundColor: this.eventColor(el).backgroundColor,
        textColor: this.eventColor(el).textColor,
        borderColor: this.eventColor(el).borderColor,
      }))
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        validRange: {
          start: moment(new Date()).subtract(3, 'years').format('YYYY-MM-DD'),
        },
        events: this.calendarEvents,
        locale: idLocale,
        eventClick: this.handleEventClick,
        eventDidMount: info => {
          new BPopover({
            propsData: {
              content: info.event.title,
              triggers: 'hover',
              target: info.el,
              placement: 'top',
            },
          }).$mount()
        },
      }
    },
  },
  mounted() {
    if (!this.projectList.length) {
      this.getProject()
    }
  },
  methods: {
    getProject() {
      /* eslint-disable no-nested-ternary */
      this.$store.dispatch('getMyProject', {
        id: this.$root.role === 2
          ? this.selectedPerson === 'all'
            ? null
            : this.$store.getters.getCurrentUser.user.id
          : this.$store.getters.getCurrentUser.user.id,
      })
    },
    eventColor(data) {
      const twoWeeksBefore = new Date(Date.now() - 12096e5)
      const twoWeeksAfter = new Date(Date.now() + 12096e5)
      const reminderDate = new Date(data.tgl_reminder)

      if (data.status === 0) {
        return {
          backgroundColor: 'rgba(234,84,85,.12)',
          textColor: '#EA5457',
          borderColor: 'rgba(234,84,85,.12)',
        }
      }

      if (reminderDate >= twoWeeksBefore && reminderDate <= twoWeeksAfter) {
        return {
          backgroundColor: 'rgba(40,199,111,.12)',
          textColor: '#28c76f',
          borderColor: 'rgba(40,199,111,.12)',
        }
      }

      return {
        backgroundColor: 'rgba(255,159,67,.12)',
        textColor: '#ff9f43',
        borderColor: 'rgba(255,159,67,.12)',
      }
    },
    handleEventClick(data) {
      this.$router.push({ name: 'project-detail', params: { id: data.event.id.toString() } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc .fc-day-today {
  .dark-layout & {
    background: #364B46 !important;
  }
}

.fc .fc-scrollgrid {
  border-bottom-width: 1px !important;
  border-radius: 4px;
}
</style>
